import { useState, useEffect } from "react";

const useWindowSize = () => {
  const isBrowser = () => typeof window !== "undefined";

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!isBrowser()) return { width: 0, height: 0, isMobile: false };

  return {
    width: windowSize.width,
    height: windowSize.height,
    isMobile: windowSize.width <= 768,
    isTablet: windowSize.width <= 1024,
  };
};

export default useWindowSize;
