export const PARTNER_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment PartnerFields on Partner {
    id
    name
    colors
    dashboardSettings
    appSettings
    subdomain
    logo {
      id
      name
      url
      title
    }
  }
`;

export const GET_PARTNER_FOR_APP = /* GraphQL */ `
  ${PARTNER_FIELDS_FRAGMENT}
  query GetPartnerForApp($input: GetPartnerForAppInput!) {
    getPartnerForApp(input: $input) {
      data {
        ...PartnerFields
      }
    }
  }
`;
