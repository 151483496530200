
import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePartnerContext } from './index';
import Loader from '../../components/loader';

type Props = {
    children: React.ReactNode;
};

export const PartnerGuard: React.FC<Props> = ({ children }) => {

    const { t } = useTranslation();

    const { loading, partner } = usePartnerContext();

    if (loading) {
        return <div id={"PartnerGuardLoader"} className={"h-[100vh] flex items-center justify-center"}>
            <Loader />
        </div>
    }

    if (!loading && !partner) return <div className={"h-[100vh] flex items-center justify-center"}>
        {t('partner.notFound')}
    </div>;

    return <>{children}</>;
}