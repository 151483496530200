import { Amplify } from "aws-amplify";
import { SignInOutput } from "@aws-amplify/auth";
import React, { useMemo, useState, useEffect, useContext, useCallback, createContext } from "react";

import { usePartnerContext } from "../partner";
import StudentService from "../../services/lms";
import { ServiceResponse } from "../../services/types";
import ServiceDeskService from "../../services/service-desk";
import { IStudent } from "../../services/lms/student/base/types";
import CognitoService, { CognitoUser } from "../../services/cognito";
import { IConversation } from "../../services/service-desk/conversation/base/types";

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: String(process.env.GATSBY_USER_POOL_ID),
            userPoolClientId: String(process.env.GATSBY_USER_POOL_WEB_CLIENT_ID)
        }
    },
});

type AuthContextType = {
    student?: IStudent;
    cognitoUser?: CognitoUser;
    loading: boolean;
    error?: string;
    working: boolean;
    setStudent: (student: IStudent) => void;
    signIn: (email: string) => Promise<ServiceResponse<SignInOutput>>;
    signOut: () => Promise<void>;
    confirmSignIn: (code: string) => Promise<ServiceResponse<boolean>>;
    refreshStudent: () => Promise<void>;
    conversations?: IConversation[];
    loadConversations: () => Promise<void>;
    guarded: boolean;
    setGuarded: (value: boolean) => void;
};


// ============== CONTEXT ==============
export const AuthContext = createContext({} as AuthContextType);

// ============== PROVIDER ==============
type Props = {
    children: React.ReactNode;
};
export function AuthProvider({ children }: Props) {

    const { partner, mailbox, loadMailbox } = usePartnerContext();

    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [working, setWorking] = useState(false);
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(undefined);
    const [student, setStudent] = useState<IStudent | undefined>(undefined);
    const [conversations, setConversations] = useState<IConversation[] | undefined>(undefined);
    const [guarded, setGuarded] = useState(true);

    const reset = () => {
        setLoading(true);
        setCognitoUser(undefined);
        setStudent(undefined);
    };

    const initialize = useCallback(async () => {

        let cognitoUser: CognitoUser | undefined;

        if (student?.email || !partner?.id) {
            return;
        }

        // get user
        try {
            if (await CognitoService.checkLoggedIn()) {
                cognitoUser = await CognitoService.getUser();
            }
        } catch (error) {
            console.error("auth-provider", "initialize", error);
        }

        const userId = cognitoUser?.["custom:userId"];

        if (!cognitoUser || !userId) {
            setLoading(false);
            return;
        }

        // HH: Get user and their courses
        const newStudent = await StudentService.Student.getStudentWith({ id: userId })

        await loadMailbox();

        if (!newStudent) {
            console.warn("auth-provider", "initialize", "no user");
            return;
        }
        // set user
        setLoading(false);
        setCognitoUser(cognitoUser);
        setStudent(newStudent.data);

    }, [student?.email, partner?.id]);

    const refreshStudent = useCallback(async () => {

        if (!cognitoUser) {
            return;
        }

        const userId = cognitoUser?.["custom:userId"];

        if (!userId) return;

        const newStudent = await StudentService.Student.getStudentWith({ id: userId })

        setStudent(newStudent.data);

    }, [cognitoUser, setStudent]);

    // LOGIN
    const signIn = useCallback(
        async (email: string) => {
            let response: ServiceResponse<SignInOutput>;

            setError(undefined);
            setWorking(true);

            const partnerId = partner?.id;
            if (!partnerId) {
                console.warn("authProvider", "login", "no partner")
                setError("Partner not valid");
                setWorking(false);
                return { success: false, errors: [{ message: "Partner not valid." }] };
            }

            const studentPartner = await StudentService.Student.getByStudentEmailWebsite({
                email,
                partnerId,
            })

            const username = studentPartner?.cognitoUsername;
            if (!username) {
                setError(`Student not found for email ${email}`);
                setWorking(false);
                return { success: false, errors: [{ message: "Student not found." }] };
            }

            try {
                response = await CognitoService.signIn(username);
                setWorking(false);
                return response;
            } catch (error) {
                setWorking(false);
                console.error("authProvider", "login", error);
                setError("Something went wrong when trying to log you in. Please try again.");
                return { success: false, errors: [{ message: "Could not sign in." }] };
            }
        },
        [partner?.id]
    );

    // LOGOUT
    const signOut = useCallback(async () => {
        setWorking(true)
        await CognitoService.signOut();
        setWorking(false);
        reset();
    }, [initialize]);

    // ANSWER CHALLENGE
    const confirmSignIn = useCallback(
        async (code: string) => {
            setWorking(true);
            const result = await CognitoService.confirmSignIn(code);
            await initialize();
            setWorking(false);
            return result;
        },
        [initialize]
    );

    const loadConversations = useCallback(async () => {
        if (!mailbox) {
            return
        }

        const response = await ServiceDeskService.Conversation.getMyConversations(mailbox.id);
        if (!response || !response.data || !response.data) {
            return;
        }

        setConversations(response.data);
    }, [mailbox]);

    useEffect(() => {
        loadConversations();
    }, [loadConversations]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const memoizedValue = useMemo(
        () =>
        ({
            guarded,
            setGuarded,
            student,
            cognitoUser,
            loading,
            signIn,
            signOut,
            confirmSignIn,
            error,
            setStudent,
            working,
            refreshStudent,
            conversations,
            loadConversations,
        } as AuthContextType),
        [signIn, signOut, confirmSignIn, cognitoUser, student, setStudent, loading, error, working, refreshStudent, conversations, loadConversations, guarded, setGuarded]
    );

    return (
        <AuthContext.Provider value={memoizedValue}>
            {children}
        </AuthContext.Provider>
    );
}

// ============== HOOK ==============
export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context)
        throw new Error("useAuthContext context must be use inside AuthProvider");
    return context;
};